import { defineAsyncComponent } from "vue";

import { SpendPage } from "./pages";

import LoadingSpinner from "@two-ui/components/LoadingSpinner.vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { createRouter, createWebHistory } from "vue-router";
import AuthCallback from "@workspace/packages/recipient/src/views/AuthCallback.vue";
import { usePayoutGlobalStore } from "@workspace/packages/payout/src/global-store";
import { RouteNames } from "@workspace/packages/payout/src/utils/routes";

export const routes = [
  {
    path: "/:token",
    name: "payout-link-home",
    component: SpendPage,
    children: [
      {
        path: "recipient",
        name: "recipient-details",
        meta: { requiresAuth: true, requiresRedemptionData: true },
        component: async () =>
          defineAsyncComponent({
            loader: () =>
              import("../../recipient/src/views/RecipientDetails.vue"),
            loadingComponent: LoadingSpinner,
          }),
      },
      {
        path: "recipient/card",
        name: "recipient-card",
        meta: { requiresAuth: true, requiresRedemptionData: true },
        component: async () =>
          defineAsyncComponent({
            loader: () =>
              import("../../recipient/src/views/RecipientCardDetails.vue"),
            loadingComponent: LoadingSpinner,
          }),
      },
      {
        path: "recipient/logout",
        name: "recipient-logout",
        meta: { requiresAuth: false },
        component: async () =>
          defineAsyncComponent({
            loader: () =>
              import("../../recipient/src/views/RecipientLogout.vue"),
            loadingComponent: LoadingSpinner,
          }),
      },
    ],
  },
  { path: "/callback", component: AuthCallback },
];
export const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (from.meta.scrollTopOnLeave || to.meta.scrollTopOnEnter) {
      return { top: 0, left: 0 };
    }
    return savedPosition || undefined;
  },
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const store = usePayoutGlobalStore();

  if (to.meta.requiresAuth) {
    try {
      await getAccessTokenSilently();
    } catch (error) {
      console.warn(
        "Session expired or authentication failed. Redirecting to login.",
        error
      );
      return loginWithRedirect();
    }
  }

  if (to.meta.requiresRedemptionData && to.params.token) {
    try {
      await store.ensureRedemptionData(to.params.token.toString());
    } catch (error) {
      console.error("Failed to load redemption data:", error);
      return next({
        name: RouteNames.Error,
        query: { message: "Failed to load redemption details" },
      });
    }
  }

  next();
});
