import { createAuth0 } from "@auth0/auth0-vue";

export const auth0 = createAuth0({
  domain: import.meta.env.VITE_AUTH0_DOMAIN_RECIPIENT,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID_RECIPIENT,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: import.meta.env.VITE_AUTH0_AUDIENCE_RECIPIENT,
  },
});
